import React , { useEffect , useState } from 'react';
import MenuMobileStyle from './MenuMobileStyle';
import imageLogo from '../../assets/images/sprzedaj-toner-logo.png';

const MenuMobile = ({ dataMenu , mm , setMm , setShowForm , userData , setShowLogin , userLogout , slideTo , refTop, path ="/" }) => {

    const [overlayClass,setOverlayClass] = useState('hideMenuStart');
    const [c,setC] = useState(0);

    useEffect(() => {
        if (c+1 > 1){
            setOverlayClass('hideMenu');
        }
        setC(v => v+1);
    },[mm]);

    const handleClickMenu = (event,obj) => {
        event.preventDefault();
        if (obj.slide!==undefined && obj.slide){
            if (path == '/' || obj.slide === 'contact') slideTo(obj.slide);
            else document.location = '/#'+obj.slide;
        } else if (obj.express!==undefined) {
            setShowForm(1);
        } else if (obj.sell!==undefined) {
            setShowForm(2);
        } else if (obj.url!==undefined && obj.url){
            document.location = obj.url;
        }
        setMm(false)
    }

    return (
        <MenuMobileStyle className={mm ? 'showMenu' : overlayClass}>
            <div className="menuMobile">
                <div className="logo" onClick={() => {
                    setMm(false);
                    slideTo('top');
                }}>
                    <img src={imageLogo} alt="Sprzedaj toner" />
                </div>
                <ul>
                    {dataMenu.map((obj,index) => (
                        <li key={`menuMobile_${index}`}>
                            <a href={obj.href!==undefined ? obj.href : '#'} className={obj.className!==undefined ? obj.className : ''} onClick={ev => handleClickMenu(ev,obj)}>
                                {obj.icon!==undefined ? <obj.icon /> : null} {obj.name}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
            <div className="overlay" onClick={() => setMm(false)}>

            </div>
        </MenuMobileStyle>
    );
}
export default MenuMobile;