import styled from 'styled-components';
import { viewHdSmall , viewSd, viewMobile } from '../../actions/variables';

const ComponentStyle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 222147483647;
    background-color: rgba(0,0,0,0.7);
    .overlay{
        width: 900px;
        min-height: 300px;
        background-color: #f7f7f7;
        overflow: hidden;
        header{
            color: grey;
            padding: 20px 20px;
            background-color: var(--color-base-dark);
            color: white;
            margin-bottom: 20px;
            border-bottom: 1px solid grey;
            position: relative;
            span{
                color: var(--color-lime);
                font-weight: 500;
            }
            .stepsDots{
                position: absolute;
                top: 20px;
                right: 20px;
            }
            .errComm{
                position: absolute;
                color: red;
                text-align: center;
                width: 100%;
                left: 0px;
                bottom: -30px;
            }
        }
        .steps{
            padding: 20px;
            flex: 1;
            position: relative;
            min-height: 450px;
            .step{
                position: absolute;
                top: 0px;
                left: 100%;
                width: 100%;
                height: 100%;
                padding: 20px;
                transition: left 0.3s;
                overflow: auto;
                &.step-1{
                    left: 0px;
                }
                .header{
                    display: block;
                    position: absolute;
                    top: 0px;
                    left: 20px;
                }
            }
        }
        .buttons{
            flex: 1;
            max-height: 60px;
            height: 60px;
            /* height: 50px; */
            background-color: grey;
            padding: 11px 20px;
            text-align: right;
            button{
                padding: 8px 13px;
                border-radius: 0px;
                outline: 0px;
                border: 1px solid grey;
                background-color: #e0e0e0;
                position: relative;
                cursor: pointer;
                margin-left: 10px;
                svg{
                    position: relative;
                    top: 2px;
                    path{
                        fill: grey;
                    }
                }
                &:hover{
                    background-color: var(--color-base-dark);
                    color: white;
                    svg path{
                        fill: white;
                    }
                }
                &.close{
                    float: left;
                    background-color: black;
                    color: white;
                    margin-left: 0px;
                    svg path{
                        fill: white;
                    }
                    &:hover{
                        background-color: var(--color-base-dark);
                    }
                }
                &.unlock , &.saveProfile{
                    float: left;
                    background-color: green;
                    color: white;
                    svg path{
                        fill: white;
                    }
                    &:hover{
                        background-color: #004600;
                    }
                }
                &.editCancel{
                    float: left;
                }
                &.final{
                    background-color: var(--color-lime-dark);
                    color: white;
                    /* margin-left: 10px; */
                    border: 1px solid black;
                    &:hover{
                        background-color: black;
                    }
                    svg{
                        path{
                            fill: white;
                        }
                    }
                    &.disabled{
                        background-color: #808080;
                        border-color: #676767;
                        color: #4a4848;
                        cursor: default;
                        svg path{
                            fill: #4a4848;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewHdSmall}px){
        z-index: 222147483648;
        .overlay{
            width: 100%;
            height: 100%;
            min-height: 100%;
            .steps{
                min-height: calc(100% - 140px);
            }
        }
    }
    @media (max-width: ${viewSd}px){
        .overlay{
            width: 100%;
            height: 100%;
            min-height: 100%;
            .steps{
                .step{
                    &.step-2{
                        table{
                            width: 100%;
                            overflow-x: auto;
                        }
                    }
                    &.step-1 .body{
                        display: block;
                        .cell{
                            margin-bottom: 10px;
                        }
                    }
                    &.step-3{
                        .layerSwitchContent{
                            top: 0px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: ${viewMobile}px){
        .overlay{
            header .stepsDots{
                display: none;
            }
            .steps{
                .step{
                    padding-top: 0px;
                    &.step-3{
                        .addColumn{
                            display: block;
                            .layerSwitch{
                                h3{
                                    margin-top: 20px;
                                    &.first{
                                        margin-top: 0px;
                                    }
                                }
                                &.layerSwitchContent{
                                    margin-bottom: 20px;
                                }
                            }
                        }
                    }
                }
            }
            .buttons{
                width: 100%;
                position: absolute;
                bottom: 0px;
                button{
                    &.unlock{
                        span{
                            display: none;
                        }
                    }
                }
            }
        }
    }
`;	

export default ComponentStyle;