import styled from 'styled-components';

const Step1Style = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
    .cell{
        label{
            display: block;
            font-size: 14px;
            color: grey;
            margin-bottom: 5px;
            transition: all 0.3s;
            span{
                color: #bbbbbb;
                float: right;
                font-style: italic;
                font-size: 12px;
            }
        }
        &.disabled label{
            color: #8e8e8e;
        }
        input , select{
            display: block;
            width: 100%;
            border: 1px solid #dadada;
            padding: 15px 20px;
            color: grey;
            background-color: #efefef;
            outline: 0px;
            color: black;
            transition: all 0.3s;
            &:focus{
                border-color: grey;
                background-color: white;
            }
            &:disabled{
                color: #808080 !important;
                background-color: #d8d8d8;
                cursor: not-allowed;
            }
        }
        .icon{
            position: relative;
            .react-datepicker-wrapper{
                display: block;
            }
            svg{
                position: absolute;
                top: 10px;
                right: 10px;
                width: 30px;
                height: 30px;
                cursor: pointer;
                path{
                    fill: grey;
                }
                &:hover{
                    path{
                        fill: black;
                    }
                }
            }
        }
        &.error{
            label{
                color: red;
            }
            input{
                background-color: #ffd3d3;
                border-color: red;
            }
        }
        &.cell-group{
            display: flex;
            flex-direction: row;
            .grouped{
                /* padding-top: 1px; */
                flex: 1;
                &:nth-child(1){
                    padding-right: 10px;
                }
                &.postcode{
                    max-width: 120px;
                }
                &.street{
                    min-width: 160px;
                }
                &.home{
                    max-width: 80px;
                    margin-right: 10px;
                }
                &.dep{
                    max-width: 100px;
                }

            }
        }
    }
`;	

export default Step1Style;