import React, { useEffect } from 'react';
import Switch from "react-switch";
import Step3Style from './Step3Style';

const Step3 = ({ delivery, setDelivery, bank, setBank, content , setContent , check , goStep , addrUps , setAddrUps }) => {

    useEffect(() => {
        if (check){
            goStep(4);
        }
    },[check]);

    const actionClickDelivery = (event, value) => {
        setDelivery(value)
    }

    return (
        <Step3Style className={(delivery === 1) ? 'addColumn' : ''}>
            <div className="layerSwitch">
                <h3 className="first">Sposób dostawy</h3>
                <div className="deliveryLayer">
                    <div className="delivery" onClick={(ev) => actionClickDelivery(ev, 0)}>
                        <Switch 
                            onChange={v => null} 
                            checked={delivery === 0} 
                            uncheckedIcon={false}
                        />
                        <div className="label">Osobiście</div>
                    </div>
                    <div className="delivery" onClick={(ev) => actionClickDelivery(ev, 1)}>
                        <Switch 
                            onChange={v => null}
                            checked={delivery === 1}
                            uncheckedIcon={false}
                        />
                        <div className="label">Kurier DPD</div>
                    </div>
                    <div className="delivery" onClick={(ev) => actionClickDelivery(ev, 2)}>
                        <Switch 
                            onChange={v => null}
                            checked={delivery === 2}
                            uncheckedIcon={false}
                        />
                        <div className="label">PickUp DPD (nadam paczkę u przewoźnika)</div>
                        {delivery === 2 &&
                            <a className={"button"} href="https://www.dpd.com/pl/pl/dpd-pickup/znajdz-punkt-pickup/" target="_blank">Znajdź punkt DPD-Pickup</a>
                        }
                    </div>
                    <div className="delivery" onClick={(ev) => actionClickDelivery(ev, 3)}>
                        <Switch 
                            onChange={v => null}
                            checked={delivery === 3}
                            uncheckedIcon={false}
                        />
                        <div className="label">Kurier inny</div>
                    </div>
                    <div className="delivery" onClick={(ev) => actionClickDelivery(ev, 4)}>
                        <Switch 
                            onChange={v => null}
                            checked={delivery === 4}
                            uncheckedIcon={false}
                        />
                        <div className="label">Wyślę paczkę na własny koszt</div>
                    </div>
                    
                </div>
            </div>
            {(delivery === 1) &&
                <div className="layerSwitch">
                    <h3>Adres podjęcia paczki (jeśli jest inny niż dane w formularzu)</h3>
                    <div className="contentAdressUps">
                        <textarea value={addrUps} onChange={ev => setAddrUps(ev.target.value)} />
                    </div>
                </div>
            }
            <div className="layerSwitch layerSwitchContent">
                <h3>Dodatkowy opis/informacja dla odbiorcy</h3>
                <textarea value={content} onChange={ev => setContent(ev.target.value)} />
            </div>
        </Step3Style>
    );
}
export default Step3;