import styled from 'styled-components';

const Step3Style = styled.div`
    
    &.addColumn{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 10px;
        .layerSwitch{
            &.layerSwitchContent{
                grid-column: span 2;
                position: relative;
                top: -20px;
                margin-bottom: -54px;
            }
        }
    }
    .layerSwitch{
        transition: all 0.3s;
        h3{
            color: var(--color-base-dark);
            border-bottom: 1px solid #cecece;
            font-weight: 300;
            padding: 0px 0px 10px 15px;
        }
        &:nth-child(1){
            margin-right: 20px;
        }
        .deliveryLayer{
            padding-top: 0px;
            .delivery{
                display: flex;
                flex-direction: row;
                margin-bottom: 10px;
                cursor: pointer;
                .label{
                    position: relative;
                    top: 5px;
                    left: 10px;
                    color: #545454;
                    font-weight: 300;
                    user-select: none;
                }
                .button{
                    display: block;
                    text-decoration: none;
                    margin-left: 20px;
                    padding: 7px 8px 3px 8px;
                    background-color: #066791;
                    color: white;
                    border-radius: 3px;
                    font-size: 14px;
                    line-height: 14px;
                    &:hover{
                        background-color: #a9c028;
                    }
                }
            }
        }
        input[type=text] , textarea{
            display: block;
            width: 100%;
            padding: 15px 20px;
            font-weight: 300;
            outline: 0px;
            font-size: 16px;
            color: grey;
            background-color: #f3f3f3;
            border: 1px solid #cecece;
            transition: all 0.3s;
            &:focus{
                background-color: white;
                border-color: black;
            }
        }
        textarea{
            min-height: 140px;
            max-height: 140px;
        }
        &.layerSwitchContent{
            grid-column: span 2;
        }
        .contentAdressUps{
            position: relative;
            z-index: 10;
            textarea{
                min-height: 160px;
                max-height: 160px;
            }
            label{
                margin-top: 10px;
                display: block;
                font-weight: 300;
                color: grey;
            }
            input{
                display: block;
                width: 100%;
                padding: 15px 20px;
                font-weight: 300;
                outline: 0px;
                font-size: 16px;
                color: grey;
                background-color: #f3f3f3;
                border: 1px solid #cecece;
                transition: all 0.3s;
                &:focus{
                    background-color: white;
                    border-color: black;
                }
            }
        }
    }
`;	

export default Step3Style;