import React, { useState , useEffect, useRef, useCallback } from 'react';

import { FaPlus , FaTimes } from 'react-icons/fa';
import { RadioGroup, RadioButton, ReversedRadioButton } from 'react-radio-buttons';
import useMedia from 'use-media';

import imgCat1 from '../../../assets/images/kategoria-A.png';
import imgCat2 from '../../../assets/images/kategoria-B.png';
import imgCat3 from '../../../assets/images/kategoria-C.png';
import imgCat4 from '../../../assets/images/kategoria-D.png';
import { getCatLetter } from '../../../actions/functions';
import { viewSd } from '../../../actions/variables';
import { urlPhotos } from '../../../actions/api';
import Upload from '../../Upload/Upload';
import Step2Style from './Step2Style';
import Stars from './Stars/Stars';

const firms = ['Brother','Canon','Dell','DYMO','Epson','HP','IBM','Lexmark','Oki','Panasonic','Ricoh','Samsung','Sharp','Xerox','Minolta','Kyocera','Lanier Toner','Toshiba','Philips','Olivetti']

const FirmCombo = (firm,index,setFieldChange,clearError) => {
    return (
        <select onChange={ev => {
            setFieldChange(index,ev.target.value,'firm');
            clearError(index,'firm');
        }} value={firm}>
            <option value="">-- wybierz markę --</option>
            {firms.map((obj,index) => <option key={`option_${index}`} value={obj}>{obj}</option>)}
        </select>
    )
}

const maxFiles = 50;

const TypeList = ({obj,setFieldChange,index}) => {
    return (
        <select value={obj.ory} onChange={ev => setFieldChange(index,ev.target.value,'ory')}>
            <option value={true}>Oryginał</option>
            <option value={false}>Zamiennik</option>
        </select>
    )
}

const Step2 = ({ check , goStep, products, setProducts , setCheckStep , Notify , toners , setToners }) => {

    const refDown = useRef();
    const [catName,setCatName] = useState('');
    const [catImage,setCatImage] = useState(null);
    const [catImageTitle,setCatImageTitle] = useState('');
    const [errors,setErrors] = useState([]);
    const isResponsive = useMedia({maxWidth: viewSd+'px'});

    const isEmpty = useCallback(() => {
        let empty = true;
        products.forEach(obj => {
            if (obj.firm || obj.nr || obj.c || (toners === 'full' && obj.cat)) empty = false;
        });
        return empty;
    }, [products, toners]);

    const hasErrors = useCallback((_Err) => {
        let err = 0;
        _Err.forEach(obj => {
            if (obj.firm || obj.nr || obj.c || ( toners==='full' && obj.cat)) err = 1;
        });
        if (err === 0 && isEmpty()){
            err = 2;
        }
        return err;
    }, [toners, isEmpty]);

    useEffect(() => {
        if (check){
            console.log('check!');
            const _Err = [];
            setCheckStep(null);
            products.forEach((obj,index) => {
                if (obj.nr !== '' || obj.c>0 || obj.cat>0 || obj.firm !== ''){
                    _Err[index] = {
                        nr: false,
                        c: false,
                        cat: false,
                        firm: false
                    }
                    if (obj.nr === '') _Err[index].nr = true;
                    if (obj.c === '' || Number(obj.c) <= 0) _Err[index].c = true;
                    if (obj.cat === 0) _Err[index].cat = true;
                    if (obj.firm === '') _Err[index].firm = true;
                }
            });
            setErrors(_Err);
            const errCheck = hasErrors(_Err); 
            if (errCheck === 0) {
                goStep(3);
            } else {
                // setErrComm('Wypełnij przynajmniej jeden wiersz z tonerem');
                if (errCheck === 1) Notify('Formularz wymiany','Uzupełnij poprawnie wiersz z tonerem','error');
                else Notify('Formularz wymiany','Wypełnij przynajmniej jeden wiersz z tonerem','error');
            }
        }
    },[check, Notify, goStep, hasErrors, products, setCheckStep]);

    useEffect(() => {
        if (catName !== ''){
            switch (catName){
                case 'A': setCatImage(imgCat1); setCatImageTitle('Idealne pudełko'); break;
                case 'B': setCatImage(imgCat2); setCatImageTitle('Pudełko z defektami'); break;
                case 'C': setCatImage(imgCat3); setCatImageTitle('Otwarte pudełko'); break;
                default: setCatImage(imgCat4); setCatImageTitle('Toner bez pudełka'); break;
            }
        }
    },[catName]);

    const setFieldChange = (index, text, field) => {

        setProducts(old => old.map((el,ind) => {
            if (ind === index){
                if (typeof el[field] === 'object'){
                    el[field] = text;
                } else {
                    el[field] = text;
                }
            }
            return el;
        }))
    }

    const getCatRowName = (index) => {
        const f = products.find((obj,ind) => ind === index);
        if (f){
            return getCatLetter(f.cat);
        }
        return '';

    }

    const handleClickRemovePhotoRow = index => {
        setProducts(old => old.filter((obj,ind) => ind !== index));
    }

    const handleAddButton = () => {
        setProducts(old => [...old,...[{ firm: '', nr: '', c: '', photos: [], cat: 0, loading: false }]]);
        setTimeout(() => {
            refDown.current.scrollIntoView({ behavior: 'smooth' });
        },100);
    }

    const checkColError = (index,field) => {
        if (errors[index] !== undefined && errors[index][field]){
            return true;
        }
        return false;
    }

    const clearError = (index,field) => {
        if (errors[index] !== undefined && errors[index][field]){
            setErrors(v => v.map((obj,ind) => {
                if (ind === index){
                    obj[field] = false;
                }
                return obj;
            }));
        }
        return false;
    }

    const handleRemovePhoto = (event,index,index2,photo) => {
        event.preventDefault();

        const found = products.find((el,ind) => ind === index);
        if (found!==undefined){
            const foundPhoto = found.photos.find((el2,ind2) => index2 === ind2);
            if (foundPhoto !== undefined){
                console.log('found.photos: ',found.photos);
                // api('removePhoto','',{ photo: foundPhoto });
                setFieldChange(index, found.photos.filter((ph,ind2) => ind2 !== index2 ),'photos');
            }
        }
    }

    return (
        <Step2Style>
            {(catImage !==null && !isResponsive) &&
                <div className="categoryImage">
                    <div className="title">{catImageTitle}</div>
                    <img src={catImage} alt={"Kategoria "+catName} />
                </div>
            }
            <div className="typeOfToners">
                <RadioGroup onChange={setToners} horizontal value={toners}>
                    <RadioButton pointColor={'#066791'} value={'full'} iconSize={20}><span className="radioText" style={{ textAlign: 'right', display: 'block', marginRight: 15 }}>Posiadam pełne oryginalne tonery</span></RadioButton>
                    <ReversedRadioButton pointColor={'#066791'} value={'empty'} iconSize={20}><span className="radioText">Posiadam puste tonery</span></ReversedRadioButton>
                </RadioGroup>
            </div>
            <div className={!isResponsive ? "layerTable" : ''}>
                <table onMouseOut={() => setCatImage(null)}>
                    {!isResponsive &&
                        <thead onMouseOut={() => setCatImage(null)}>
                            <tr>
                                <td>Marka</td>
                                <td>Numer katalogowy</td>
                                <td className="quantity">Ilość</td>
                                {toners==='full' ? 
                                    <td colSpan={2}>Kategoria {catName!=='' ? <span className="cat">{catName}</span> : ''}</td> :
                                    <>
                                        <td>Typ</td>
                                        <td>&nbsp;</td>
                                    </>
                                }
                            </tr>
                        </thead>
                    }
                    <tbody>
                        {products.map((obj,index) => (
                            <tr key={`firm_${index}`}>
                                <td className={checkColError(index,'firm') ? 'error' : ''}><label>Marka</label>{FirmCombo(obj.firm,index,setFieldChange,clearError)}</td>
                                <td className={checkColError(index,'nr') ? 'error' : ''}>
                                    <label>Nr. katalogowy</label>
                                    <input value={obj.nr} onChange={ev => setFieldChange(index,ev.target.value,'nr')} onFocus={() => clearError(index,'nr')} />
                                </td>
                                <td onMouseOver={() => setCatImage(null)} className={checkColError(index,'c') ? 'error' : ''}>
                                    <label>Ilość</label>
                                    <input type="number" value={obj.c} onChange={ev => setFieldChange(index,ev.target.value,'c')} onFocus={() => clearError(index,'c')} />
                                </td>
                                {toners === 'full' && 
                                    <td onMouseOut={() => setCatImage(null)} className={checkColError(index,'cat') ? 'error' : ''}>
                                        <label className="cat">Kategoria <span className="cat">{getCatRowName(index)}</span></label>
                                        <Stars stars={obj.cat} action={star => setFieldChange(index,star,'cat')} setCatName={setCatName} clearError={() => clearError(index,'cat')} />
                                    </td>
                                }
                                {toners !== 'full' &&
                                    <td>
                                        <label>Typ</label><TypeList obj={obj} setFieldChange={setFieldChange} index={index} />
                                    </td>
                                }
                                <td onMouseOver={() => setCatImage(null)}>{ 
                                    <div className={(index>0 ? 'tdButtons' : '')+(obj.loading ? ' loadingLayer' : '')+(obj.photos?.length>0 ? ' photos' : '')}>
                                        {obj.photos.map((ph,index2) => (
                                            <div className="photo" key={`photo_${ph}`}>
                                                <a href="" onClick={event => handleRemovePhoto(event,index,index2,ph)}><FaTimes /></a>
                                                <img src={urlPhotos+'small/'+ph} className="image" />
                                            </div>
                                        ))}
                                        {obj.photos.length < 3 &&
                                            <Upload
                                                url="addPhoto"
                                                beforeAction={() => setFieldChange(index,true,'loading')}
                                                afterAction={() => setFieldChange(index,false,'loading')}
                                                successAction={result => setFieldChange(index,[...obj.photos,...[result]],'photos')}
                                                addLabel={obj.photos.length === 0}
                                                Notify={Notify}
                                            />
                                        }
                                        {(index>0 && obj.photos.length ===0) && <button href="" className="remove" onClick={() => handleClickRemovePhotoRow(index)}><FaTimes /></button>}
                                    </div>
                                }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {(products.length<maxFiles) && <button className="add" onClick={handleAddButton}><FaPlus /> Dodaj nowy</button>}
            <div ref={refDown} />
        </Step2Style>
    );
}
export default Step2;