import React from 'react';

import Helmet from 'react-helmet';

const Smartsup = () => {

    return (
        <Helmet>
            <script>
                {`
                var _smartsupp = _smartsupp || {};
                    _smartsupp.key = '568c6ff50eb13b7e490de64e5e3c629888c14859';
                    window.smartsupp||(function(d) {
                    var s,c,o=smartsupp=function(){ o._.push(arguments)};o._=[];
                    s=d.getElementsByTagName('script')[0];c=d.createElement('script');
                    c.type='text/javascript';c.charset='utf-8';c.async=true;
                    c.src='https://www.smartsuppchat.com/loader.js?';s.parentNode.insertBefore(c,s);
                    })(document);
                `}
            </script>
        </Helmet>
    );
}
export default Smartsup;