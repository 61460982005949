import React , { useState , useRef , useEffect, useLayoutEffect } from "react";

import { FaHome, FaQuestion , FaEnvelopeOpenText , FaDollarSign , FaStackExchange , FaCogs } from 'react-icons/fa'
import NotificationSystem, { childContextTypes } from 'react-notification-system';
import { useQueryParam, StringParam } from "use-query-params";
import { reactLocalStorage } from 'reactjs-localstorage';
import useMedia from 'use-media';

// import HeaderRate from '../../sections/HeaderRate/HeaderRate';
import FormValuating from '../FormValuating/FormValuating';
import FormExchange from '../FormExchange/FormExchange';
import FormUtylize from '../FormUtylize/FormUtylize';
import FormSell from '../FormSell/Form';
import Header from '../../sections/Header/Header';
import Cookies from "../Cookies/Cookies";
import Comment from '../Comment/Comment';
import SeoMeta from "../SeoMeta/SeoMeta";
import MenuMobile from '../../sections/MenuMobile/MenuMobile';
import Forget from '../../sections/Forget/Forget';
import { isEmpty } from '../../actions/functions';
import Login from '../../sections/Login/Login';
import StylesGlobal from '../../StylesGlobal';
import TemplateStyle from './TemplateStyle';
import Map from '../../sections/Map/Map';
import api from '../../actions/api';
// import Messanger from "../Messanger/Messanger";
// import Messanger2 from "../Messanger2/Messanger2";
import Smartsup from "../Smartsup/Smartsup";
import { viewSd } from "../../actions/variables";


const Template = props => {

    const [tkn, setTkn] = useQueryParam("token", StringParam);
    const refNotify = useRef();
    const [refCategories,setRefCategories] = useState(useRef());
    const [refForms,setRefForms] = useState(useRef());
    const [refFaq,setRefFaq] = useState(useRef());
    const refContact = useRef();
    const refTop = useRef();
    const isResponsive = useMedia({maxWidth: viewSd+'px'});

    const [mm,setMm] = useState(false);
    const [showForm,setShowForm] = useState(null);
    const [userData,setUserData] = useState(null);
    const [userToken,setUserToken] = useState('');
    const [readUserDataStorage,setReadUserDataStorage] = useState(0);
    const [showLogin,setShowLogin] = useState(false);
    const [showForget,setShowForget] = useState(false);
    const [dataComment,setDataComment] = useState(null);

    useEffect(() => {
        if (props.showForm){
            setShowForm(props.showForm);
            // console.log('sss: ',props.showForm);
        }
        if (props.refForms){
            setRefForms(props.refForms);
        }
        if (props.refCategories){
            setRefCategories(props.refCategories);
        }
        if (props.refFaq){
            setRefFaq(props.refFaq);
        }
    },[props.showForm,props.refForms,props.refFaq,props.refCategories]);

    useEffect(() => {
        const tempUserData = reactLocalStorage.getObject('userData');
        if (!isEmpty(tempUserData)){
            setUserData(tempUserData);
        }
        const tempUserToken = reactLocalStorage.get('userToken');
        if (tempUserToken !== undefined){
            setUserToken(tempUserToken);
        }
    },[readUserDataStorage]);

    useEffect(() => {
        if (tkn!='' && tkn!==undefined){
            setShowForget(true);
        }
    },[]);

    const dataMenu = [
        { name: 'Strona główna', className: 'mainPage', icon: FaHome , slide: 'top', url: '/' },
        { name: 'Kategoria tonerów', icon: FaCogs , slide: 'categories' },
        { name: 'Marki tonerów', icon: FaCogs , url: '/marki.html' },
        { name: 'Sprzedaj', icon: FaDollarSign , sell: true },
        { name: 'FAQ' , icon: FaQuestion , url: '/faq.html' },
        { name: 'Kontakt' , icon: FaEnvelopeOpenText , url: 'kontakt.html' },
        { name: 'Ekspresowa wycena' , className: 'express' , icon: FaStackExchange , express: true },
    ]

    const userLogout = () => {
        api('logout',userToken,{},r => {
            reactLocalStorage.remove('userToken');
            reactLocalStorage.remove('userData');
            setUserData(null);
            setUserToken('');
        });
    }

    const Notify = (title ,message, level= 'success') => {
        refNotify.current.addNotification({
            title,
            message,
            level,
            position: 'tc'
        });
    }

    const slideTo = slide => {
        if (slide === 'categories'){
            refCategories.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'forms'){
            refForms.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'faq'){
            refFaq.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'contact'){
            refContact.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        } else if (slide === 'top'){
            refTop.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }

    return (
        <>
            {/* <Messanger2 /> */}
            <noscript>
                <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MTBHGLF" height={0} width={0} style={{ display: 'none', visibility: 'hidden' }} />
            </noscript>
            <SeoMeta path={props.path} />
            <StylesGlobal />
            <NotificationSystem ref={refNotify} />

            <MenuMobile 
                dataMenu={dataMenu} 
                mm={mm} 
                setMm={setMm} 
                setShowForm={setShowForm} 
                userData={userData} 
                setShowLogin={setShowLogin} 
                userLogout={userLogout} 
                slideTo={slideTo} 
                refTop={refTop}
                path={props.path}
            />
            <TemplateStyle id="wrapper">

                <Header 
                    dataMenu={dataMenu} 
                    setMm={setMm} 
                    setShowForm={setShowForm} 
                    userData={userData} 
                    setShowLogin={setShowLogin} 
                    userLogout={userLogout} 
                    slideTo={slideTo} 
                    refTop={refTop} 
                    setShowForget={setShowForget}
                    userToken={userToken}
                    setTkn={setTkn}
                    path={props.path}
                />

                {props.path === '/' ? 
                    props.children :
                    <div className="contentWidth underPage">
                        {props.children}
                    </div>
                }

                <Map refContact={refContact} />

            </TemplateStyle>
            {showForm==1 &&
                <FormValuating setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForm==2 &&
                <FormSell setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForm==3 &&
                <FormExchange setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForm==4 &&
                <FormUtylize setShowForm={setShowForm} showForm={showForm} Notify={Notify} userData={userData} userToken={userToken} setDataComment={setDataComment} />
            }
            {showForget &&
                <Forget setShowForget={setShowForget} token={tkn} setReadUserDataStorage={setReadUserDataStorage} Notify={Notify} />
            }

            {dataComment!==null && <Comment data={dataComment} setData={setDataComment} />}
            <Cookies />

            {showLogin &&
                <Login setShowLogin={setShowLogin} setReadUserDataStorage={setReadUserDataStorage} Notify={Notify} />
            }

            {/* <Messanger /> */}
            {((isResponsive && !showForm && !showLogin && !dataComment) || !isResponsive) &&
                <Smartsup />
            }
        </>
    )
}

export default Template
