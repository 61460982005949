import React , { useState , useEffect, useRef } from 'react';
import { FaPlus , FaTimes } from 'react-icons/fa';
import Step3Style from './Step3Style';

const firms = ['Brother','Canon','Dell','DYMO','Epson','HP','IBM','Lexmark','Oki','Panasonic','Ricoh','Samsung','Sharp','Xerox','Minolta','Kyocera','Lanier Toner','Toshiba','Philips','Olivetti']

const FirmCombo = (firm,index,setFieldChange,setError) => {
    return (
        <select onChange={ev => {
            setFieldChange(index,ev.target.value,'firm');
            setError(false);
        }} value={firm}>
            <option value="">-- wybierz markę --</option>
            {firms.map((obj,index) => <option key={`option_${index}`} value={obj}>{obj}</option>)}
        </select>
    )
}

const maxFiles = 50;

const Step3 = ({ check , goStep , productsWants , setProductsWants , Notify , setCheckStep }) => {

    const refDown = useRef();
    const [error,setError] = useState(false);

    useEffect(() => {
        if (check){
            setCheckStep(null);
            let err = true;
            productsWants.forEach((obj,index) => {
                if (obj.name!='' && obj.c>0 && obj.form!=''){
                    err = false;
                }
            });
            if (!err) {
                goStep(4);
            } else {
                setError(true);
                // setErrComm('Wypełnij przynajmniej jeden wiersz z tonerem');
                Notify('Formularz wymiany','Wypełnij przynajmniej jeden wiersz z tonerem','error');
            }
        }
    },[check]);

    const setFieldChange = (index, text, field) => {

        setProductsWants(old => old.map((el,ind) => {
            if (ind === index){
                el[field] = text;
            }
            return el;
        }))
    }

    const handleAddButton = () => {
        setProductsWants(old => [...old,...[{ firm: '', name: '', c: '' }]]);
        setTimeout(() => {
            refDown.current.scrollIntoView({ behavior: 'smooth' });
        },100);
    }

    const handleRemoveRow = index => {
        setProductsWants(old => old.filter((obj,ind) => ind!==index));
    }

    return (
        <Step3Style>
            <div className="layerTable">
                <table>
                    <thead>
                        <tr>
                            <td>Marka</td>
                            <td>Nazwa</td>
                            <td className="quantity">Ilość</td>
                            <td>&nbsp;</td>
                        </tr>
                    </thead>
                    <tbody>
                        {productsWants.map((obj,index) => (
                            <tr key={`firm_${index}`}>
                                <td className={error && index===0 && obj.firm == '' ? 'error' : ''}><label>Marka</label>{FirmCombo(obj.firm,index,setFieldChange,setError)}</td>
                                <td className={error && index===0 && obj.name == '' ? 'error' : ''}><label>Nazwa</label><input value={obj.name} onChange={ev => setFieldChange(index,ev.target.value,'name')} onFocus={() => setError(false)} /></td>
                                <td className={error && index===0 && obj.c == '' ? 'error' : ''}><label>Ilość</label><input type="number" value={obj.c} onChange={ev => setFieldChange(index,ev.target.value,'c')} onFocus={() => setError(false)} /></td>
                                <td style={{ maxWidth: 40 }}><button className={"remove"+(productsWants.length<=1 ? ' disabled' : '')} disabled={productsWants.length<=1} onClick={() => handleRemoveRow(index)}><FaTimes color="red" /></button></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            {(productsWants.length<maxFiles) && <button className="add" onClick={handleAddButton}><FaPlus /> Dodaj nowy</button>}
            <div ref={refDown} />
        </Step3Style>
    );
}
export default Step3;